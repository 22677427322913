import React, { Component } from "react";
import { Elements, StripeProvider, CardElement } from "react-stripe-elements";
import { loadStripe } from "@stripe/stripe-js";
import Header from "./components/header.js";
import Footer from "./components/footer.js";
import Donate from "./components/donate.js";
import DonationOptions from "./components/donation-options.js";
import LearnMoreButton from "./components/learn-more.js";
import SocialComponent from "./components/social-component.js";
import Checkout from "./pages/page-donate.js";
import { Link, withRouter } from "react-router-dom";
import heroImage from "./images/hero_image.png";
import "./App.scss";
const data = require("./data/data.json");

class App extends Component {
  renderBody = () => {
    return (
      <div>
        {data.body.map((paragraph) => {
          return <p>{paragraph}</p>;
        })}
      </div>
    );
  };

  render() {
    return (
      <div>
        <Header />
        <div className="topTitle">
          <h1>{data.title}</h1>
        </div>
        <div className="row">
          <div className="double-column">
            <div className="column">
              <div className="section-main">
                <div className="section-main-title">
                  <img src={heroImage}></img>
                  <div style={{ minWidth: "300px" }}></div>
                </div>

                <div className="section-main-description">
                  <p>
                    As the conflict in Ukraine continues to unfold, many of us
                    are watching from afar and wondering how we can help those
                    affected by this humanitarian crisis.
                  </p>
                  <p>
                    The <b>WAGN Ecosystem</b>, composed of wealth management
                    firms and the service companies that support wealth
                    managers, invites you to participate in our Ukraine Relief
                    Campaign. When you make a donation, please choose the
                    company that referred you to this campaign. WAGN will match
                    the first $5,000 of donations that come through WAGN
                    referrals.
                  </p>
                  <p>
                    The Ukraine Relief Campaign, powered by the 501(c)(3) public
                    charity Givinga Foundation, will direct funds into
                    organizations that are providing immediate support to
                    Ukraine and its people. Each of the organizations listed
                    below is actively working to provide aid and relief services
                    to those affected.
                  </p>
                  <p>
                    <br />
                    <br />
                    <b>US-Based Ukrainian Organizations</b>
                    <br />
                    <b>
                      <a href="https://novaukraine.org/" target="_blank">
                        Nova Ukraine
                      </a>
                    </b>{" "}
                    - Nova Ukraine is a non-profit organization focused on
                    providing humanitarian relief to vulnerable populations in
                    Ukraine and raising awareness about Ukraine throughout the
                    world. EIN: 46-5335435
                    <br />
                    <b>
                      <a href="https://razomforukraine.org/" target="_blank">
                        Razom
                      </a>
                    </b>{" "}
                    - Working to unlock the full potential of Ukraine, Razom is
                    working with Ukraine-centered nonprofits to bring emergency
                    medical supplies and other forms of humanitarian aid to the
                    front lines. EIN: 46-4604398
                    <br />
                    <b>
                      <a href=" https://www.rsukraine.org/ " target="_blank">
                        Revived Soldiers Ukraine
                      </a>
                    </b>{" "}
                    - By providing medical and humanitarian help to Ukraine,
                    Revived Soldiers of Ukraine is working to help Ukrainians
                    fulfill their fundamental human rights and freedoms. EIN:
                    47-5315018
                    <br />
                    <br />
                    <b>Global Aid Organizations</b>
                    <br />
                    <b>
                      <a href="https://www.unhcr.org/en-us/" target="_blank">
                        USA for UNHCR: The United Nations Refugee Agency
                      </a>
                    </b>{" "}
                    - USA for UNHCR, the United Nations Refugee Agency, is
                    dedicated to safeguarding the rights and well-being of
                    people forced to flee from Ukraine by providing emergency
                    supply kits, setting up transit centers, and providing
                    temporary shelter. EIN: 52-1662800
                    <br />
                  </p>
                  <br />

                  <p>
                    Donors have the option to pay all applicable processing fees
                    so that 100 percent of the donation goes to the charitable
                    organization you choose. Your donation will be collected by
                    the Givinga Foundation and then remitted directly to the
                    charity you choose.
                  </p>

                  <br />
                  <p>
                    <b>About the Givinga Foundation</b>
                    <br />
                    <i>
                      Founded on the idea that everyone deserves an equal
                      opportunity to make a meaningful impact, the Givinga
                      Foundation is a 501(c)(3) public charity dedicated to
                      removing the financial barriers to charitable giving.
                      Through their partnership with technology provider
                      Givinga, the Givinga Foundation gives brands the
                      opportunity to find new and innovative ways to maximize
                      the impact of their charitable donations.
                    </i>
                    <br />
                    <br />
                    <b>
                      <i>
                        If your business is interested in standing up a campaign
                        to support the humanitarian crisis in Ukraine or any
                        other global event or disaster, reach out to us at
                        hello@givinga.com to get started right away.
                      </i>
                    </b>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="column">
            <div className="section-donate">
              <Donate />
              <DonationOptions />
              <SocialComponent />
              <div class="section-donate-disclaimer">
                *While we work to ensure accuracy, the stats indicated on this
                page may not be an exact reflection of actual activity.
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withRouter(App);
