import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Header from "../components/header";
import Footer from "../components/footer.js";
import SocialComponent from "../components/social-component";
import { withRouter } from "react-router-dom";
import "./page-thank-you.scss";
import hearts from "../images/thank-you-hearts.png";
import { getDonationDetail } from "../api/getDonationDetail";
import currencyFormatter from "currency-formatter";
const currencies = require("../data/currencies.json");

const charityNames = {
  913288: "Nova Ukraine",
  899398: "Razom",
  1000046: "Revived Soldiers Ukraine",
  1051340: "USA for UNHCR",
};

const reactToPdf = () => {
  window.print();
};

const ThankYouPage = (match) => {
  const query = new URLSearchParams(match.location.search);
  const [donationDetail, setDonationDetail] = useState(undefined);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (donationDetail === undefined) {
      (async () => {
        setLoading(true);
        const id = query.get("session_id");
        const detail = await getDonationDetail(id);
        setDonationDetail(detail.response);
        setLoading(false);
      })();
    }
  }, [query, donationDetail]);

  return (
    <div>
      <Header />
      <div className="container-page-thank-you">
        <div className="container-page-thank-you-title">
          <center>
            <img className="img-container" src={hearts}></img>
            <h1>Thank you for donating!</h1>
          </center>
        </div>
      </div>
      <div className="receipt">
        <div className="receipt-title">Your Donation Receipt</div>
        <div className="receipt-text">
          {loading ? (
            <>Loading receipt...</>
          ) : donationDetail ? (
            <p>
              <span>{Date(donationDetail.created * 1000)}</span>
              <br />
              <br />
              <span>{donationDetail.name},</span> <br />
              <br />
              <b>
                Your donation of{" "}
                {currencyFormatter.format(
                  donationDetail.amount /
                    currencies[donationDetail.currency.toLowerCase()],
                  { code: donationDetail.currency }
                )}
                {donationDetail.currency.toUpperCase()} to{" "}
                {charityNames[donationDetail.charityId]}
              </b>{" "}
              has been received by{" "}
              <span style={{ fontFamily: "Helvetica" }}>
                The WAGN Ukraine Relief Campaign{" "}
              </span>
              .<br />
              <br />
              No goods or services were provided in exchange for these
              contributions. The Givinga Foundation is an exempt organization as
              described in Section 501(c)(3) of the Internal Revenue Code with
              an EIN # 47-4172718.
              <br />
              <br />
              By making this donation, you are making a contribution to a
              donor-advised fund at the Givinga Foundation. The Givinga
              Foundation is a nonprofit 501(c)(3) that facilitates the granting
              of funds to the specific nonprofit this donation is being
              processed for.
              <br />
              <br />
              Understand for tax benefit purposes; all transactions are
              processed by the Givinga Foundation; as such, your bank/credit
              card statements will reflect payments being processed by the
              Givinga Foundation.
              <br />
              <br />
              <span style={{ fontFamily: "Helvetica" }}>
                {" "}
                Please{" "}
                <span
                  onClick={() => reactToPdf()}
                  style={{
                    cursor: "pointer",
                    color: "rgba(67, 140, 222, 1.0)",
                    textDecoration: "underline",
                  }}
                >
                  print a copy of this page
                </span>{" "}
                for record keeping and tax purposes.
              </span>
            </p>
          ) : (
            <p>Failed to load</p>
          )}
        </div>
      </div>
      <div className="thank-you-social-container">
        <div className="thank-you-social-container-help">
          <p>HELP BY SHARING</p>
        </div>
        <div className="thank-you-social-container-icons">
          <center>
            <div data-network="facebook" className="st-custom-button">
              <img src={require("../images/social/facebook.png")} />
            </div>
            <div data-network="twitter" className="st-custom-button">
              <img src={require("../images/social/twitter.png")} />
            </div>
            <div data-network="linkedin" className="st-custom-button">
              <img src={require("../images/social/linkedin.png")} />
            </div>
            <div data-network="email" className="st-custom-button">
              <img src={require("../images/social/email.png")} />
            </div>
          </center>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default withRouter(ThankYouPage);
