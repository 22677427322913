import React from "react";
import { Link, withRouter } from "react-router-dom";
import history from "../history";
import "./donation-options.scss";
import CurrencyInput from "react-currency-input";
import Modal from "react-modal";
import Terms from "../pages/page-terms";

const companyNames = [
  "Wealth Advisor Growth Network",
  "SDR Ventures",
  "Compass CFO Solutions",
  "Elk River Wealth Management",
  "Advisor Assist",
  "LibertyFi",
  "Goldenrod Companies",
  "Graham Media Partners",
  "Naviter Wealth",
  "Merchant",
  "AiK2 Insurance Services",
  "Primark Capital",
];

class DonationOptions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      amount: "",
      charityId: 0,
      terms: false,
      showModal: false,
      currency: "usd",
      company: "",
    };

    this.cancel = "";

    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleAmountChange = this.handleAmountChange.bind(this);
    this.handleTermsChange = this.handleTermsChange.bind(this);
    this.handleCurrencyChange = this.handleCurrencyChange.bind(this);
    this.handleRouting = this.handleRouting.bind(this);
    this.handleACHRouting = this.handleACHRouting.bind(this);
    this.handleCharityChanged = this.handleCharityChanged.bind(this);
    this.handleCompanyChanged = this.handleCompanyChanged.bind(this);
  }

  handleAmountChange(event) {
    var amount = event.target.value.replace(/[^0-9 ]/g, "");
    this.setState({ amount: amount });
  }

  handleAmountChange(event, maskedvalue, floatvalue) {
    this.setState({ amount: floatvalue });
  }

  handleTermsChange(event) {
    this.setState({ terms: event.target.checked });
  }

  handleCurrencyChange(event) {
    this.setState({ currency: event.target.value });
  }

  handleCompanyChanged(event) {
    this.setState({ company: event.target.value });
  }

  handleCharityChanged(event) {
    this.setState({ charityId: event.target.value });
  }

  async handleRouting() {
    if (
      this.state.amount >= 1 &&
      this.state.terms &&
      this.state.charityId !== "" &&
      this.state.company !== ""
    ) {
      history.push({
        pathname: "/donate",
        state: {
          amount: this.state.amount,
          currency: "usd",
          charityId: this.state.charityId,
          company: this.state.company,
        },
      });
    } else {
      alert(
        `Please make sure you have provided a donation amount of at least $1, selected a charity, selected a company, and agreed to the terms and conditions.`
      );
    }
  }

  handleACHRouting() {
    if (
      this.state.amount >= 1 &&
      this.state.terms &&
      this.state.charityId !== "" &&
      this.state.company !== ""
    ) {
      if (this.state.currency !== "usd") {
        alert(`Donations via Bank Transfer must be in USD.`);
      } else {
        history.push({
          pathname: "/donate-ach",
          state: {
            amount: this.state.amount,
            currency: "usd",
            charityId: this.state.charityId,
            company: this.state.company,
          },
        });
      }
    } else {
      alert(
        `Please make sure you have provided a donation amount of at least $1, selected a charity, selected a company, and agreed to the terms and conditions.`
      );
    }
  }

  handleOpenModal() {
    this.setState({ showModal: true });
  }

  handleCloseModal() {
    this.setState({ showModal: false });
  }

  render() {
    return (
      <div className="donation-container">
        <div className="donation-container-help">
          <p>HELP BY DONATING</p>

          <select
            className="select"
            onChange={this.handleCharityChanged}
            value={this.state.charityId}
          >
            <option value="">Select a charity</option>
            <option value={913288}>Nova Ukraine</option>
            <option value={899398}>Razom</option>
            <option value={1000046}>Revived Soldiers Ukraine</option>
            <option value={1051340}>USA for UNHCR</option>
          </select>

          <select
            className="select"
            onChange={this.handleCompanyChanged}
            value={this.state.company}
          >
            <option value="">Select a company</option>
            {companyNames.map((company, index) => (
              <option value={company}>{company}</option>
            ))}
          </select>

          <CurrencyInput
            className="currencyInput"
            prefix=""
            value={this.state.amount}
            onChangeEvent={this.handleAmountChange}
          />

          <div className="terms">
            <h5>
              <input
                type="checkbox"
                onChange={this.handleTermsChange}
                value={this.state.terms}
              />
              I accept the{" "}
              <Link onClick={this.handleOpenModal}>Terms & Conditions</Link>
            </h5>
            <Modal
              isOpen={this.state.showModal}
              onRequestClose={this.handleCloseModal}
              className="Modal"
              overlayClassName="Overlay"
              contentLabel="Terms and Conditions"
            >
              <Terms closeModal={this.handleCloseModal} />
            </Modal>
          </div>

          <div className="button">
            <button onClick={this.handleRouting}>Donate via Credit Card</button>
          </div>
          <div className="button">
            <button onClick={this.handleACHRouting}>Donate via ACH</button>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(DonationOptions);
